var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progressCom"},[_c('XModal',{staticClass:"work-timeAxis",attrs:{"width":"70%","height":"90%","name":_vm.name,"showCrossBtn":true},on:{"beforeOpen":_vm.dataInit,"opened":function($event){return _vm.open()}}},[_c('div',{staticClass:"header_tit",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"sel"},[(_vm.name != 'staffDataPic')?_c('div',{staticClass:"header_sel"},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"开始日期"},on:{"change":_vm.handleChange},model:{value:(_vm.timeStart),callback:function ($$v) {_vm.timeStart=$$v},expression:"timeStart"}}),_c('div',{staticStyle:{"background":"#ffffff"}},[_vm._v("至")]),_c('el-date-picker',{attrs:{"type":"date","placeholder":"结束日期"},on:{"change":_vm.handleChange},model:{value:(_vm.timeEnd),callback:function ($$v) {_vm.timeEnd=$$v},expression:"timeEnd"}})],1):_vm._e()]),_c('div',{staticClass:"tit_text"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.activeBar ? _vm.activeBar.name : "")+" ")]),_c('span',[_vm._v(_vm._s((" " + (_vm.name == "memberDatatimeAxisPhoto" ? "状态的" : "的") + (_vm.IsRealTimeScreenshot ? "截图" : "详情")))+" ")])]),_c('a',[_c('i',{staticClass:"hiFont hi-close-thin",on:{"click":_vm.onCancel}})])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"content_modal"},[(_vm.progressPhotoArr && _vm.progressPhotoArr.length)?[_c('div',{staticClass:"time_line"},[_c('el-timeline',_vm._l((_vm.progressPhotoArr),function(value,index){return _c('el-timeline-item',{key:index,attrs:{"timestamp":value.time.timeFormat('yyyy-MM-dd HH:mm:ss'),"placement":"top","color":"#409eFF"}},[_c('el-card',[_c('h4',[_c('span',[_c('span',[_vm._v("进程名："+_vm._s(value.pname)+" ")]),_c('span',[_vm._v("别名： "),(
                          value.FocusFormAlias && value.FocusFormAlias.length
                        )?_vm._l((value.FocusFormAlias),function(nitem,index){return _c('label',{key:index},[_vm._v(_vm._s(index == 0 ? nitem : ("、" + nitem)))])}):_c('label',[_vm._v("无")])],2),_c('span',[_vm._v("工作状态： "),(
                          value.FocusFormStatus &&
                          value.FocusFormStatus.length
                        )?_vm._l((value.FocusFormStatus),function(nitem,index){return _c('label',{key:index},[_vm._v(_vm._s(index == 0 ? nitem : ("、" + nitem)))])}):_c('label',[_vm._v("无")])],2)]),_c('span',[_c('span',[_vm._v("窗口名："+_vm._s(value.fname))])]),_c('span',[_c('span',[_vm._v("行为状态："+_vm._s(value.BehaviorStatus ? value.BehaviorStatus : "未知")+" ")]),(value.MouseClickAverage)?_c('span',[_vm._v("鼠标点击速度："+_vm._s(value.MouseClickAverage)+"次每分钟 ")]):_vm._e(),(value.KeysAverage)?_c('span',[_vm._v("键盘输入速度："+_vm._s(value.KeysAverage)+"次每分钟 ")]):_vm._e(),_c('span',[_vm._v("操作效率："),_c('label',{style:(("color:" + (_vm.getEfficiencyColor(
                          value.EfficiencyStatus
                        ))))},[_vm._v(_vm._s(value.EfficiencyStatus ? value.EfficiencyStatus : "无"))])])])]),(_vm.IsRealTimeScreenshot)?_c('el-image',{staticStyle:{"max-width":"200px"},attrs:{"src":_vm.cmurl + value.imgURL,"alt":"","preview-src-list":[_vm.cmurl + value.imgURL]}}):_vm._e()],1)],1)}),1)],1),(_vm.name != 'staffDataPic')?_c('div',[_c('CPages',{on:{"changeEvent":_vm.handlePaginationChange},model:{value:(_vm.pageData),callback:function ($$v) {_vm.pageData=$$v},expression:"pageData"}})],1):_vm._e()]:_c('div',{staticClass:"empty-proccess-list"},[_c('img',{attrs:{"src":require("../../assets/img/emptyTask.png"),"alt":""}}),_c('p',[_vm._v("暂无进程截图")])])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }