//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getEfficiencyColor } from "@/commons";
export default {
  data() {
    return {
      timeStart: null,
      timeEnd: null,
      photoDis: false,
      progressPhotoArr: [],
      last: "",
      morePhoto: true,
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 10,
        totalNum: 0,
      }, //分页
    };
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    selActiveTime: {
      type: String,
      default: null,
    },
    gid: {
      type: Number,
      default: null,
    },
    teamValue: {
      type: Number,
      default: null,
    },
    activeBar: {
      type: Object,
      default: function () {
        return {};
      },
    },
    stime: {
      type: Date | String,
      default: "",
    },
    etime: {
      type: Date | String,
      default: "",
    },
    uid: {
      type: Number,
      default: null,
    },
    name: {
      type: String,
      default: "memberDatatimeAxisPhoto",
    },
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  components: {
    CPages: () => import("@/components/CPages"),
    XModal: () => import("@/components/XModal.vue"),
  },
  methods: {
    getEfficiencyColor,
    /**
     * 关闭弹窗
     */
    onCancel() {
      this.$modal.hide(this.name);
    },
    /**
     * 时间筛选
     */
    handleChange() {
      this.pageData.pageIndex = 1;
      this.getBarData();
    },
    /**
     * 分页
     */
    handlePaginationChange(val) {
      this.pageData = val;
      this.getBarData();
    },
    dataInit() {
      Object.assign(this.$data, this.$options.data());
    },
    open() {
      this.$nextTick(() => {
        if (this.selActiveTime) {
          this.timeStart = this.selActiveTime;
          this.timeEnd = this.selActiveTime;
        } else {
          this.timeStart = this.stime;
          this.timeEnd = this.etime;
        }

        if (this.name == "staffDataPic") {
          this.progressPhotoArr = this.activeBar.fromList.map((m) => {
            return {
              fname: m.FocusFromName,
              imgURL: m.ImageName,
              pname: this.activeBar.name,
              time: m.ImageTime,
            };
          });
        } else {
          this.getBarData();
        }
      });
    },
    /**
     * 工作状态截图数据
     */
    getBarData() {
      if (this.name == "memberDatatimeAxisPhoto") {
        this.getProcessImgData();
      } else if (
        this.name == "allfoftwarepic" ||
        this.name == "allbehaviorpic"
      ) {
        this.getProcessImgWithFormData([this.activeBar.name]);
      } else if (this.name == "keywordfrequency") {
        this.getProcessImgWithFormData(
          this.activeBar.pname.map((m) => m.FocusFormName)
        );
      } else if (this.name == "behaviorPicList") {
        this.getUserBehaviorImg();
      } else if (this.name == "sensitiveWord") {
        this.getSensitiveWordImg();
      }
    },
    /**
     * 敏感词截图
     */
    getSensitiveWordImg() {
      this.photoDis = true;
      this.loading = true;
      let params = {
        uid: this.uid,
        word: this.activeBar.name,
        stime: this.stime,
        etime: this.etime,
        pageCount: this.pageData.pageSize,
        teamId: this.teamValue,
        page: this.pageData.pageIndex,
      };
      this.$http
        .post("/SensitiveWord/GetSensitiveWordImg.ashx#", params)
        .then((res) => {
          if (res.res == 0) {
            this.progressPhotoArr = res.data.data;
            this.pageData.totalNum = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    getProcessImgData() {
      this.photoDis = true;
      this.loading = true;
      let params = {
        uid: this.uid,
        stime: this.timeStart.timeFormat("yyyy-MM-dd"),
        etime: this.timeEnd.timeFormat("yyyy-MM-dd"),
        // last: this.last,
        pageCount: this.pageData.pageSize,
        status: this.activeBar.name,
        teamId: this.teamValue,
        gid: this.gid,
        page: this.pageData.pageIndex,
      };
      this.$http
        .get("/General/GetProcessImg.ashx#", { params: params })
        .then((res) => {
          if (res.res == 0) {
            this.progressPhotoArr = res.data.data;
            this.pageData.totalNum = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    getProcessImgWithFormData(val) {
      this.photoDis = true;
      this.loading = true;
      let params = {
        uid: this.uid,
        // stime: this.last ? this.last : this.stime,
        stime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01")
          : this.timeStart.timeFormat("yyyy-MM-dd"),
        etime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59")
          : this.timeEnd.timeFormat("yyyy-MM-dd"),
        pageCount: this.pageData.pageSize,
        pname: val,
        teamId: this.teamValue,
        page: this.pageData.pageIndex,
      };
      this.$http
        .post("/General/GetProcessImgWithForm.ashx#", params)
        .then((res) => {
          if (res.res == 0) {
            this.progressPhotoArr = res.data.data;
            this.pageData.totalNum = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
    getUserBehaviorImg() {
      this.loading = true;
      let params = {
        behavior: this.activeBar.name,
        stime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01")
          : this.timeStart.timeFormat("yyyy-MM-dd 00:00:01"),
        etime: this.selActiveTime
          ? this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59")
          : this.timeEnd.timeFormat("yyyy-MM-dd 23:59:59"),
        pageCount: this.pageData.pageSize,
        teamId: this.teamValue,
        page: this.pageData.pageIndex,
        uid: this.uid,
      };
      this.$http
        .post("/User/Work/GetUserBehaviorImg.ashx", params)
        .then((res) => {
          if (res.res == 0) {
            this.progressPhotoArr = res.data.data;
            this.pageData.totalNum = res.data.total;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
